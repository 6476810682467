$hugeIcon: 24px;
$bigIcon: 16px;
$defaultIcon: 12px;

.fullWidth {
  width: 100%;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-spacing-xx-small);
  max-width: 100%;
  font-family: var(--font-family);
  color: var(--color-dominant-on-dominant);
  font-size: 14px;
  font-weight: var(--font-weight-small);
  line-height: 1;
  border: var(--border-thin);
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  background: transparent;
  gap: var(--spacing-spacing-xxx-small);

  .content {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0;
    min-width: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    box-sizing: border-box;
    transition: opacity 150ms var(--transition-bezier);
    gap: var(--spacing-spacing-xxx-small);

    span {
      padding: 4px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: inherit;
      box-sizing: border-box;
    }
  }

  &.fullWidth {
    width: 100% !important;
    border-radius: var(--border-radius-medium);

    .content > span {
      justify-content: center;
    }

    &.isDropdown .content > span {
      justify-content: flex-start;
    }
  }

  &.selected {
    background: var(--color-primary-100);
  }

  &:disabled {
    opacity: .5;

    &:hover {
      opacity: .5;
    }

    .icon {
      opacity: .8;
    }
  }

  &:focus {
    outline: none;
  }

  &.reverse {
    flex-flow: row-reverse nowrap;
  }

  .icon {
    width: $defaultIcon;

    svg {
      transform: rotate(0);
      transform-origin: center;
      transition: transform 150ms linear;
      width: $defaultIcon;
      height: $defaultIcon;
      fill: var(--color-dominant-on-dominant)
    }

    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .badge {
    height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--color-secondary-100);
    transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  // Size
  &.small {
    height: var(--size-sizing-small);
    border-radius: var(--border-sizing-large);
  }

  &.medium {
    height: var(--size-sizing-medium);
    border-radius: var(--border-radius-medium);
  }

  &.large {
    padding: 0 var(--spacing-spacing-x-small);
    height: var(--size-sizing-large);
    font-size: var(--font-size-font-size-4);
    border-radius: var(--border-radius-medium);
  }

  // Themes
  &.primary {
    background: linear-gradient(103deg, var(--startColor) -13.82%, var(--endColor) 121.29%);
    --startColor: var(--color-green-300);
    --endColor: var(--color-blue-300);
    transition: --startColor 0.2s, --endColor 0.2s;
    &:hover {
      --endColor: var(--color-green-300);
    }
    &.disabled {
      background: var( --color-tertiary-100);
    }
  }
  &.secondary {
    background: var(--color-dominant-white);
    border: var(--border-thin);

    &.active {
      background: var(--color-primary-100);
    }

    &:hover {
      background: var(--color-primary-300, #86FFBD);
    }

    &.selected {
      background: var(--color-tertiary-050);
    }
    &.disabled {
      background: var(--color-dominant-white);
    }
  }

  &.tertiary, &.tertiaryReverse {
    background: transparent;
    border: 1px solid transparent;

    &.active, &.selected {
      border: var(--border-thin);
      background: var(--color-tertiary-040);

      .badge {
        background: var(--color-tertiary-100);
      }
    }

    &.selected {
      border: var(--border-sizing-medium) solid var(--color-dominant-tertiary);
    }

    &:hover {
      background: var(--color-tertiary-100);
      .badge {
        background: var(--color-tertiary-040);
      }
    }
    &.disabled {
      background: transparent;
    }
  }

  &.tertiaryNoBorder {
    background: var(--color-tertiary-025);
    &:hover {
      background: var(--color-tertiary-100);
    }

    &.selected {
      background: var(--color-tertiary-050);
    }
  }

  &.tertiaryReverse {
    background: transparent;
    &:hover {
      background: var(--color-dominant-white);
    }
    &.selected {
      background: var(--color-tertiary-050);
    }
  }

  &.tone {
    background: var(--color-tertiary-090);
    border-color: transparent;

    &.active {
      border: var(--border-thin);
      background: var(--color-tertiary-090);
    }

    &:hover {
      background: var(--color-primary-300);
    }

    &.disabled {
      background: var(--color-tertiary-100);
      opacity: var(--opacity-disabled);
    }

    &.selected {
      background: var(--color-primary-100);
    }
  }

  &.shopify {
    color: var(--color-dominant-white);
    background: #008060;

    &:hover {
      background: rgba(#008060, .8);
    }
    &.selected {
      background: var(--color-tertiary-050);
    }
  }

  &.link {
    background: transparent;
    border: none;

    color: inherit;
    font-weight: inherit;
    font-size: inherit;

    &:hover {
      background: transparent;
      color: inherit;
      text-decoration: underline;
    }
    &.selected {
      background: var(--color-tertiary-050);
    }

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.onlyIcon {
    width: 40px;
    height: 40px;
    padding: 0;

    &.small {
      width: 32px;
      height: 32px;
    }

    &.large {
      width: 48px;
      height: 48px;
    }

    &:focus {
      box-shadow: none;
    }

    .icon {
      margin: 0;
    }

    &:disabled .icon:hover {
      background: transparent;
    }
  }

  &.withIcon {
    display: flex;

    .content {
      white-space: nowrap;
    }
  }
}

.spanWrapper {
  display: inline-block;
  width: fit-content;
}
